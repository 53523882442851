import React from 'react';
import PropTypes from 'prop-types';

const A = ({href, text}) => {
	return (
		<a href={href} target="_blank" rel="noopener noreferrer">
			{text}
		</a>
	);
};

A.propTypes = {
	href: PropTypes.string,
	text: PropTypes.string,
};

export default A;
