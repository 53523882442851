import React from 'react';
import './style.scss';
import classNames from 'classnames';
export const CaseStudyMetadata = ({children}) => {
	return <div className="gst-case-study-metadata">{children}</div>;
};

export const CaseStudyMetadataBlock = ({children, className}) => {
	return (
		<div className={classNames(`gst-case-study-metadata-block ${className}`)}>
			{children}
		</div>
	);
};

export const CaseStudyMetadataItem = ({label, children}) => {
	return (
		<div className="gst-case-study-metadata-item">
			<label>{label}</label>
			<span>{children}</span>
		</div>
	);
};
