import React from 'react';
import PropTypes from 'prop-types';
import A from './A';
import {Icon} from '@gisatcz/ptr-atoms';
import {
	CaseStudyMetadata,
	CaseStudyMetadataBlock,
	CaseStudyMetadataItem,
} from '../CaseStudyMetadata';

import './style.scss';
import classNames from 'classnames';

const CaseStudyAttributions = ({
	website,
	period,
	region,
	client,
	consortium,
}) => {
	const getLinksOrText = content => {
		//if array, then transform to the links separated by ","
		if (content?.length > 0) {
			return (
				<div className={'gst-web-attributions-links'}>
					{content.reduce(
						(acc, value, index, array) => [
							...acc,
							<div key={value.href}>
								<A {...value} />
								{array.length - 1 !== index ? ', ' : null}
							</div>,
						],
						[]
					)}
				</div>
			);
		} else if (typeof content === 'object') {
			// return link
			return <A {...content} />;
		} else {
			// return text
			return content;
		}
	};
	const caseStudyMetadataBlockClasses = classNames({
		sited: website,
	});
	return (
		<CaseStudyMetadata>
			<CaseStudyMetadataBlock className={caseStudyMetadataBlockClasses}>
				{period ? (
					<CaseStudyMetadataItem label="Period">{period}</CaseStudyMetadataItem>
				) : null}
				{region ? (
					<CaseStudyMetadataItem label="Country/Region">
						{region}
					</CaseStudyMetadataItem>
				) : null}
				{client ? (
					<CaseStudyMetadataItem label="Client">
						{getLinksOrText(client)}
					</CaseStudyMetadataItem>
				) : null}
				{consortium ? (
					<CaseStudyMetadataItem label="Consortium">
						{getLinksOrText(consortium)}
					</CaseStudyMetadataItem>
				) : null}
			</CaseStudyMetadataBlock>
			<CaseStudyMetadataBlock>
				{website ? (
					<CaseStudyMetadataItem label="Website">
						{getLinksOrText(website)}
					</CaseStudyMetadataItem>
				) : null}
			</CaseStudyMetadataBlock>
		</CaseStudyMetadata>

		// OLD attributions with icons as a possible idea for further development
		// <div className={'gst-case-study-attributions'}>
		// 	<div>
		// 		{website ? (
		// 			<div className={'gst-attributions-section'}>
		// 				<a href={website} target={'_blank'}>
		// 					{website}
		// 				</a>
		// 			</div>
		// 		) : null}
		// 	</div>
		// 	<div>
		// 		{client ? (
		// 			<CaseStudyMetadataItem label="Client">
		// 				{client}
		// 			</CaseStudyMetadataItem>
		// 			// <div className={'gst-attributions-section'}>
		// 			// 	<span>Client:</span>
		// 			// 	<label>{client}</label>
		// 			// </div>
		// 		) : null}
		// 		{organisation ? (
		// 			<div className={'gst-attributions-section'}>
		// 				<span>Organisation:</span>
		// 				<label>{organisation}</label>
		// 			</div>
		// 		) : null}
		// 	</div>
		// 	<div>
		// 		{projectLeader ? (
		// 			<div className={'gst-attributions-section'}>
		// 				<span>Project leader:</span>
		// 				<label>{projectLeader}</label>
		// 			</div>
		// 		) : null}
		// 	</div>
		// 	<div>
		// 		{period ? (
		// 			<div className={'gst-attributions-section'}>
		// 				<span className={'gst-period'}>
		// 					<Icon icon={'calendar'} />
		// 					{period}
		// 				</span>
		// 			</div>
		// 		) : null}
		// 		{region ? (
		// 			<div className={'gst-attributions-section'}>
		// 				<Icon icon={'globe'} className={'globe-icon'} />
		// 				<label>{region}</label>
		// 			</div>
		// 		) : null}
		// 	</div>
		// </div>
	);
};

CaseStudyAttributions.propTypes = {
	website: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	period: PropTypes.string,
	region: PropTypes.string,
	client: PropTypes.string,
	organisation: PropTypes.string,
	projectLeader: PropTypes.string,
};

export default CaseStudyAttributions;
