import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import CaseStudyAttributions from '../../../../components/CaseStudyAttributions';
import Breadcrumb from '../../../../components/Breadcrumb';
import {graphql, useStaticQuery} from 'gatsby';

const EOPINPage = ({pageContext}) => {
	const caseStudyAttributions = {
		website: null,
		period: pageContext.caseStudy.period,
		region: 'Ethiopia, Afghanistan',
		client: {
			href: 'https://www.clovekvtisni.cz/',
			text: 'PEOPLE IN NEED',
		},
		// organisation: null,
		// projectLeader: 'GISAT',
	};
	const data = useStaticQuery(graphql`
		{
			background: file(
				relativePath: {eq: "pages/work/caseStudies/desert.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Support to NRM activities in Ethiopia and Afghanistan" />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<Breadcrumb crumbs={pageContext?.crumbs} />
				<h1>Support to NRM activities in Ethiopia and Afghanistan</h1>
				<p>EOPIN</p>
			</Hero>
			<Block light>
				<Content>
					<Subnavigation>
						<Anchors>
							<a href={'#problem'}>The problem</a>
							<a href={'#solution'}>Solution</a>
							<a href={'#outcomes'}>Outcomes</a>
						</Anchors>
						<PersonBadge
							note={pageContext.caseStudy.contactNote}
							email={pageContext.caseStudy.contactPerson}
						/>
					</Subnavigation>
				</Content>
			</Block>
			<Block light className={'gst-attribution-block'}>
				<Content>
					<CaseStudyAttributions {...caseStudyAttributions} />
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'problem'}>The problem</h2>
					<p>
						Local communities in fragile states have been extremely vulnerable
						to deteriorating environmental conditions affecting agricultural
						productivity and livelihoods. Climate change, overgrazing, under
						maintenance increase pressure on livelihoods and induce local
						conflicts.
					</p>
					<p>
						NGOs aim to improve resilience of communities by education and
						introducing investments into sustainable environmental and
						agriculture practices. Often, they lack sufficient evidence about
						distribution and intensity of both causal factors and impacts.
						Furthermore, in absence of baseline spatial data it is difficult to
						monitor effectivity of mitigations and investments in short- to
						long-term horizons.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'solution'}>Solution</h2>
					<p>
						Generally, multi-scale and multi-temporal satellite imagery are a
						means to building tailored mapping tools to support investments
						planning and monitoring their effectiveness. Gisat has developed
						several custom solutions for operations of People in Need in
						Afghanistan and Ethiopia. Analytical results provided insights into
						intensity and distribution of agricultural patterns and practices,
						level of impacts of drought, erosion, overexploitation or conflicts,
						population distribution and accessibility of water or other sources.
						Geostatistical assessment and time series analysis facilitated
						estimation of trends, identification of potential driving factors
						and determination of affected communities and population as well as
						modeling level of impact under specific real or hypothetical
						scenarios.
					</p>
				</Content>
			</Block>
			<Block light>
				<Content>
					<h2 id={'outcomes'}>Outcomes</h2>
					<p>
						Satellite mapping improved targeting of mitigation measures and
						targeting of long-term development aid resulting in building local
						capacity, improved access to water or agriculture resources,
						designing anti-erosion measures and re-forestation activities.
						Simple and intuitive visualisations and mapping outputs were a must
						to allow understanding by local authorities and villagers.
						Factual-based assessment positively shaped discussion with local
						authorities by providing indisputable evidence of levels of
						deterioration and extent of impacted population.
					</p>
				</Content>
			</Block>
		</Layout>
	);
};

EOPINPage.propTypes = {
	pageContext: PropTypes.object.isRequired,
};
export default EOPINPage;
